export class Api {
  constructor(serverUrl) {
    this.serverUrl = serverUrl;
    this.surveyId = null;
  }

  // return modules from each request to get next/prev route

  healthCheck = async () => {
    try {
      const response = await this._fetch(`/hc`);
      if (response.ok) {
        const data = await response.json();
        if (data.code !== 200) {
          throw Error("unable to reach server");
        }
        return true;
      } else {
        throw Error("unable to reach server");
      }
    } catch (e) {
      console.log(e);
    }
  };

  initialize = async () => {
    const id = new URLSearchParams(window.location.search).get("id");

    const response = await this._fetch(`/survey`, "POST", {
      uuid: id,
      step: "init",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message === "success") {
        this.surveyId = data.data.uuid;
        return data.data;
      } else {
        throw Error("failed to initialize survey");
      }
    } else {
      throw Error("failed to initialize survey");
    }
  };

  getInventoryList = async (listId) => {
    const response = await this._fetch(`/presets/inventory-items/${listId ?? ''}`, "GET", );
    if(response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      throw Error("failed to get inventory list");
    }
  }

  loadSpecialItems = async () => {
    const response = await this._fetch(`/presets/special-items`);

    const itemsWithDimensions = [
      "Large/Curved TV",
      "Grandfather Clock",
      "Animal House",
      "Existing Crates",
      "Valuable Wall Art",
    ];

    if (response.ok) {
      const data = await response.json();
      // check if any questions include a choice (yes/no, indicated by q.answers)
      const specialItems = data.data.map((item) => {
        let qs = item.questions.map((q) => {
          if (q.answers) {
            return { ...q, choice: true };
          } else {
            return q;
          }
        });

        return {
          ...item,
          questions: qs,
          dimension: itemsWithDimensions.includes(item.item),
        };
      });

      // store special items in a map to be accessed with item name
      const itemMap = specialItems.reduce((map, item) => {
        item.questions = item.questions.map((q) => {
          return {
            question: q.question,
            formatted: q.formatted,
            answer: "",
            choice: q.choice || false,
            cat: item.cat,
            id: item.id,
            ...q,
          };
        });
        map[item.item] = item;
        return map;
      }, {});

      return itemMap;
    } else {
      throw Error("failed to load special items");
    }
  };

  submitContact = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "contact",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("failed to submit contact data");
      } else {
        return data.data;
      }
    } else {
      throw Error("failed to submit contact data");
    }
  };

  submitMovingDate = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "move_date",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message === "success") {
        return data.data;
      } else {
        throw Error(data.errors);
      }
    } else {
      throw Error("failed to submit moving date");
    }
  };

  submitOriginAccess = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "origin_access",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to submit origin access data");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to submit origin access data");
    }
  };

  submitDestinationAccess = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "destination_access",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to submit destination access data");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to submit destination access data");
    }
  };

  submitOriginLocation = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "origin_location",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error(data.errors);
      } else {
        return data.data;
      }
    } else {
      throw Error("failed to send origin location data");
    }
  };

  submitDestinationLocation = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "destination_location",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to send destination location data");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to send destination location data");
    }
  };

  submitVideo = async (data) => {
    const response = await this._fetch(`/survey`, "POST", data, true);

    if (response.ok) {
      const data = await response.json();
      if (data.message === "no items detected") {
        throw Error("No items detected. Please try again.");
      } else if (data.message !== "success") {
        throw Error("Could not analyze video. Please try again.");
      } else if (data.errors) {
        throw Error("Unknown error occurred");
      }
      return data.data;
    } else {
      throw Error("Unknown error occurred");
    }
  };

  submitSpecialItems = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "special_items",
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to submit special items data");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to submit special items data");
    }
  };

  submitRoomCount = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "room_count",
    });

    if (!response.ok) {
      throw Error("Failed to submit how many rooms data");
    } else {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to submit how many rooms data");
      } else {
        return data.data;
      }
    }
  };

  submitFinalize = async (data) => {
    const response = await this._fetch(`/survey`, "POST", {
      ...data,
      step: "finalize",
    });
    if (response.ok) {
      const data = await response.json();

      if (data.message !== "success") {
        throw Error("Failed to finalize survey");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to finalize survey");
    }
  };

  getCurrentStep = async (data) => {
    const response = await this._fetch(`/steps`, "POST", {
      ...data,
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message !== "success") {
        throw Error("Failed to get current step");
      } else {
        return data.data;
      }
    } else {
      throw Error("Failed to get current step");
    }
  };

  _fetch = async (url, method = "GET", data = {}, isFormData = false) => {
    let body;
    if (isFormData) {
      body = data;
    } else {
      body = method === "GET" ? null : JSON.stringify(data);
    }

    return await fetch(`${this.serverUrl}${url}`, {
      method,
      body,
      headers: {
        "x-app-version": "1.0",
        ...(isFormData ? {} : { "Content-Type": "application/json" }),
      },
    });
  };
}
